import { gql } from '@apollo/client';

export const MyProfileQuery = gql`
query MyProfileQuery {
  user_profile {
    status {
      okay
    }
    user {
      resource {
        key
      }
      profile {
        email, first_name, last_name, picture_url
      }
      roles {
        roles
      }
    }
  }
}
`;

export const LoginMutation = gql`
mutation LoginMutation($email: String!, $password: String!) {
  user_login(email: $email, password: $password) {
    status {
      okay
      errors {
        msg
        code
      }
    }
    token
    require_change_password
  }
}
`;

export const RegisterMutation = gql`
mutation RegisterMutation(
  $firstName: String!,
  $lastName: String,
  $email: String!,
  $password: String!,
  $useRandomPassword: Boolean,
  $roles: [String!],
) {
  user_create(
    first_name: $firstName,
    last_name: $lastName,
    email: $email,
    password: $password,
    use_random_password: $useRandomPassword,
    roles: $roles,
  ) {
    user_key
    status {
      okay
      errors {
        code
        msg
      }
    }
  }
}
`;
