import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { Breadcrumb } from 'core/Breadcrumb';

const UserList = lazy(() => import('./List'));
const UserCreate = lazy(() => import('./Create'));

export const UserRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <div>
        <Breadcrumb className="hide-on-fullscreen" />
        <Routes>
          <Route
            index
            element={(<UserList />)}
          />
          <Route
            path={Pages.user.create.meta.path}
            element={(<UserCreate />)}
          />
        </Routes>
      </div>
    </Suspense>
  );
};
