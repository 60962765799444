import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { UserContext } from './UserContext';
import { AccessGate, NotLoggedIn } from './AccessGate';

export const PrivateContent = ({ reqRole, children }) => {
  const userContext = useContext(UserContext);
  if (!userContext.loggedIn) {
    return (<NotLoggedIn />);
  }

  const { user } = userContext.user;
  const isAdmin = user.roles.roles.indexOf('admin') > -1;
  if (!isAdmin) {
    if (reqRole && user.roles.roles.indexOf(reqRole) === -1) {
      return (<AccessGate role={reqRole} />);
    }
  }

  return (
    <>{children}</>
  );
};

PrivateContent.propTypes = {
  reqRole: PropTypes.string,
  children: PropTypes.element.isRequired,
};
