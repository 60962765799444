import { createGlobalStyle } from 'styled-components';
import { asRem } from 'lib/css';

export const GlobaVariables = createGlobalStyle`

:root {
  --font-family: 'DM Sans', sans-serif;
  --font-numbers: sans-serif;
  --font-hero: 'Hero New', hero-new, sans-serif;


  --color-bg: #15141B;
  --color-bg-2: #181922;
  --color-bg-3: #111;
  --color-text: #fff;
  --color-text-rev: #111;
  --color-text-2: #f4f4f4;
  --color-button-text: #111;
  --color-button-bg: #fff;
  --color-link: #6B9FD9;
  --color-primary: #F5FF78;
  --color-primary-2: rebeccapurple;
  --color-label: #989899;
  --color-label-2: #777;
  --color-disabled: #959595;
  --color-border: #282934;
  --color-highlight: #FF95E8;
  --color-error: #ffbcbc;

  --layout-top-height: ${asRem('54')};
  --layout-left-width: ${asRem('200')};
  --layout-right-width: ${asRem('200')};
  --layout-bottom-height: ${asRem('54')};
  --layout-content-max-width: ${asRem('620')};

  --layout-padding: ${asRem('30')};
  --layout-padding-box: ${asRem('20')};
  --layout-padding-contained: ${asRem('15')};
}
`;
