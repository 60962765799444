import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { SalaryRoutes } from 'hr/salary/routes';
import { LeaveRoutes } from './leave/routes';
import { OfficeRoutes } from './office/routes';

const AddressList = lazy(() => import('./address/List'));
const AddressCreate = lazy(() => import('./address/Create'));
const AddressUpdate = lazy(() => import('./address/Update'));

const EmployeeRoleList = lazy(() => import('./employeerole/List'));
const EmployeeRoleCreate = lazy(() => import('./employeerole/Create'));
const EmployeeRoleUpdate = lazy(() => import('./employeerole/Update'));

const StaffList = lazy(() => import('./List'));
const StaffUpdate = lazy(() => import('./Update'));

export const StaffRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<StaffList />)}
        />
        <Route
          path={Pages.hr.staff.update.meta.path}
          element={(<StaffUpdate />)}
        />

        <Route path={Pages.hr.staff.address.meta.path}>
          <Route
            index
            element={(<AddressList />)}
          />
          <Route
            path={Pages.hr.staff.address.create.meta.path}
            element={(<AddressCreate />)}
          />
          <Route
            path={Pages.hr.staff.address.update.meta.path}
            element={(<AddressUpdate />)}
          />
        </Route>

        <Route path={Pages.hr.staff.role.meta.path}>
          <Route
            index
            element={(<EmployeeRoleList />)}
          />
          <Route
            path={Pages.hr.staff.role.create.meta.path}
            element={(<EmployeeRoleCreate />)}
          />
          <Route
            path={Pages.hr.staff.role.update.meta.path}
            element={(<EmployeeRoleUpdate />)}
          />
        </Route>
        <Route path={Pages.hr.staff.salary.meta.starPath} element={<SalaryRoutes />} />
        <Route path={Pages.hr.staff.leave.meta.starPath} element={<LeaveRoutes />} />
        <Route path={Pages.hr.staff.office.meta.starPath} element={<OfficeRoutes />} />
      </Routes>
    </Suspense>
  );
};
