import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppContextConsumer, AppContextProvider } from './core/AppContext';
import { AppLayout } from './core/AppLayout';
import { LeftBar } from './core/LeftBar';
import { PrivateContent } from './core/PrivateContent';
import { TopBar } from './core/TopBar';
import { UserContextProvider } from './core/UserContext';
import { HrRoutes } from './hr/routes';
import { UserRoutes } from './user/routes';
import { PagesRoutes } from './pages/routes';

function App() {
  return (
    <AppContextProvider value={{}}>
      <UserContextProvider value={{}}>
        <AppContextConsumer>
          {({ viewState }) => (
            <AppLayout
              top={(<TopBar />)}
              left={(<LeftBar />)}
              state={viewState}
            >
              <div>
                <Routes>
                  <Route path="pages/*" element={<PagesRoutes />} />
                  <Route path="hr/*" element={<PrivateContent reqRole="hr"><HrRoutes /></PrivateContent>} />
                  <Route path="user/*" element={<PrivateContent reqRole="hr"><UserRoutes /></PrivateContent>} />
                  <Route index element={<PrivateContent><Home /></PrivateContent>} />
                  <Route
                    path="*"
                    element={(
                      <main style={{ padding: '1rem' }}>
                        <p>Found no pages!</p>
                      </main>
                    )}
                  />
                </Routes>
              </div>
            </AppLayout>
          )}
        </AppContextConsumer>
      </UserContextProvider>
    </AppContextProvider>
  );
}

function Home() {
  return <h2>Home</h2>;
}

export default App;
