import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { asRem } from 'lib/css';
import { AppContext } from './AppContext';
import { Pages } from './pages';

const LeftBarWrapper = styled.div`
  padding: var(--layout-padding-contained);
  nav {
    ul {
      li {
        padding-bottom: var(--layout-padding-contained);
        font-size: ${asRem(14)};
        line-height: ${asRem(18)};
        &.version-name {
          border-top: ${asRem(1)} solid;
          padding: var(--layout-padding-contained);
          /* text-align: center; */
          font-size: ${asRem(12)};
          color: var(--color-label);
        }
      }
    }
  }
`;

export const LeftBar = () => {
  const appContext = useContext(AppContext);
  const links = [
    { to: '/', name: 'Home' },
    { to: Pages.hr.staff.meta.url, name: 'Staff' },
    { to: Pages.user.meta.url, name: 'User' },
    { to: Pages.hr.staff.office.meta.url, name: 'Office' },
    { to: Pages.hr.calendar.meta.url, name: 'Calendar' },
    { to: Pages.hr.policy.meta.url, name: 'Policy' },
    { to: Pages.hr.role.jobtitle.meta.url, name: 'Job Title' },
    { to: Pages.hr.role.team.meta.url, name: 'Team' },
  ];

  return (
    <LeftBarWrapper>
      <nav>
        <ul>
          {links.map((link) => (
            <li key={link.to}>
              <Link to={link.to} className="plain">{link.name}</Link>
            </li>
          ))}
          {appContext.boot && (
            <li className="version-name">
              {'v'}
              {appContext.boot.version}
              {` (api v${appContext.boot.api_version})`}
            </li>
          )}
        </ul>
      </nav>
    </LeftBarWrapper>
  );
};

LeftBar.propTypes = {
};
