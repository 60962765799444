import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field, ErrorMessage,
} from 'formik';
import { useQuery } from '@apollo/client';
import { asRem } from 'lib/css';
import { EnumValuesQuery } from 'core/query';
import { dataFromPath, formatMoney } from 'lib/utils';
import { Config } from 'config';
import { StatusLoading } from './Status';

export const FormItemWrapper = styled.div`
  margin-bottom: ${asRem(20)};

  >.error-item {
    color: var(--color-error);
    font-size: ${asRem(14)};
    line-height: ${asRem(18)};
    font-style: italic;
    padding: ${asRem(5)};
    padding-bottom: 0;
  }

  >.help {
    color: var(--color-label);
    padding: 0 var(--layout-padding-contained);
    font-style: italic;
    font-size: ${asRem(14)};
  }

`;

export const FormItem = ({
  type, name, label, help, placeholder, options, textAreaRows = 2,
  className,
  showEmpty = true,
  ...fieldParams
}) => {
  const resolvedLabel = label || name;
  const resolvedType = type || 'text';
  const isSelect = resolvedType === 'select';
  const isTextArea = resolvedType === 'textarea';
  const isInput = !isTextArea && !isSelect;

  return (
    <FormItemWrapper className={className}>
      <label htmlFor={name}>{resolvedLabel}</label>
      {isInput && (
        <Field
          type={resolvedType}
          name={name}
          placeholder={placeholder}
          {...fieldParams}
          // Disable scroll inside number input
          onWheel={(e) => e.target.blur()}
          // Disable scroll inside number input
          onKeyDown={(e) => {
            const code = e.charCode || e.keyCode;
            if (code === 38 || code === 40) {
              e.preventDefault();
            }
          }}
        />
      )}
      {isTextArea && (
        <Field name={name} as="textarea" rows={textAreaRows} placeholder={placeholder} {...fieldParams} />
      )}
      {isSelect && (
        <div className="rz-select">
          <Field name={name} component="select" {...fieldParams}>
            {showEmpty && (
              <option value="">-</option>
            )}
            {options.map((item) => (
              <option
                key={item.key}
                value={item.value || item.key}
              >
                {item.name || item.value || item.key}
              </option>
            ))}
          </Field>
          <div className="dropdown">▼</div>
        </div>
      )}
      <ErrorMessage name={name} className="error-item" component="div" />
      {help && (
        <div className="help">
          {help}
        </div>
      )}
    </FormItemWrapper>
  );
};

FormItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  textAreaRows: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.any,
    name: PropTypes.string,
  })),
  showEmpty: PropTypes.bool,
};

export const FormItemEnum = ({ typeName, ...formOptions }) => {
  const { loading, data } = useQuery(EnumValuesQuery, {
    variables: { name: typeName },
  });

  if (!data && loading) return (<StatusLoading />);

  // eslint-disable-next-line no-underscore-dangle
  const options = data.__type.enumValues.map(
    (x) => ({ key: x.name, name: x.name.split('_').join(' ') }),
  );

  return (
    <FormItem
      {...formOptions}
      options={options}
      type="select"
    />
  );
};

FormItemEnum.propTypes = {
  typeName: PropTypes.string.isRequired,
  ...FormItem.propTypes,
};

export const FormItemYesNo = ({ ...formOptions }) => {
  const options = [
    { key: 'yes', value: true, name: 'Yes' },
    { key: 'no', value: false, name: 'No' },
  ];

  return (
    <FormItem
      {...formOptions}
      options={options}
      type="select"
    />
  );
};

FormItemYesNo.propTypes = {
  ...FormItem.propTypes,
};

export const FormItemMoney = ({
  currency, values, ...formOptions
}) => {
  const resolvedCurrency = currency || Config.defaultCurrency;
  let help = null;

  const val = dataFromPath(values, formOptions.name);
  if (val !== undefined) {
    help = formatMoney({ value: val, currency: resolvedCurrency });
  }

  return (
    <FormItem
      {...formOptions}
      help={help}
      type={formOptions.type || 'number'}
    />
  );
};

FormItemMoney.propTypes = {
  currency: PropTypes.string,
  values: PropTypes.object.isRequired,
  ...FormItem.propTypes,
};
