import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';
import { Breadcrumb } from 'core/Breadcrumb';
import { RoleRoutes } from './role/routes';
import { StaffRoutes } from './staff/routes';
import { PolicyRoutes } from './policy/routes';

const CalendarList = lazy(() => import('./calendar/List'));
const CalendarCreate = lazy(() => import('./calendar/Create'));
const CalendarUpdate = lazy(() => import('./calendar/Update'));

const CalendarEventList = lazy(() => import('./calendar/event/List'));
const CalendarEventCreate = lazy(() => import('./calendar/event/Create'));
const CalendarEventUpdate = lazy(() => import('./calendar/event/Update'));

export const HrRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <div>
        <Breadcrumb className="hide-on-fullscreen" />
        <Routes>
          <Route path={Pages.hr.calendar.meta.path}>
            <Route
              index
              element={(<CalendarList />)}
            />
            <Route
              path={Pages.hr.calendar.create.meta.path}
              element={(<CalendarCreate />)}
            />
            <Route
              path={Pages.hr.calendar.update.meta.path}
              element={(<CalendarUpdate />)}
            />
            <Route path={Pages.hr.calendar.event.meta.path}>
              <Route
                index
                element={(<CalendarEventList />)}
              />
              <Route
                path={Pages.hr.calendar.event.create.meta.path}
                element={(<CalendarEventCreate />)}
              />
              <Route
                path={Pages.hr.calendar.event.update.meta.path}
                element={(<CalendarEventUpdate />)}
              />
            </Route>
          </Route>
          <Route path={Pages.hr.role.meta.starPath} element={<RoleRoutes />} />
          <Route path={Pages.hr.staff.meta.starPath} element={<StaffRoutes />} />
          <Route path={Pages.hr.policy.meta.starPath} element={<PolicyRoutes />} />
        </Routes>
      </div>
    </Suspense>
  );
};
