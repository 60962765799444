import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const LeavePolicyList = lazy(() => import('./leavepolicy/List'));
const LeavePolicyCreate = lazy(() => import('./leavepolicy/Create'));
const LeavePolicyUpdate = lazy(() => import('./leavepolicy/Update'));

export const PolicyRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<LeavePolicyList />)}
        />
        <Route
          path={Pages.hr.policy.create.meta.path}
          element={(<LeavePolicyCreate />)}
        />
        <Route
          path={Pages.hr.policy.update.meta.path}
          element={(<LeavePolicyUpdate />)}
        />
      </Routes>
    </Suspense>
  );
};
