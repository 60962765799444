import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { asRem } from 'lib/css';

const AppLayoutWrapper = styled.div`
  height: 100%;
  min-height: 100%;

  >.top {
    height: var(--layout-top-height);
    border-bottom: ${asRem(1)} solid var(--color-border);
    background: var(--color-bg);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  >.bottom {
    height: var(--layout-bottom-height);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }

  >.content{
    display: flex;
    >.content-left, >.content-right {
      overflow: auto;
      position: fixed;
      /* top: 0;
      bottom: 0; */
      bottom: 0;
      top: var(--layout-top-height);
      padding-bottom: var(--layout-bottom-height);
    }

    >.content-left {
      width: var(--layout-left-width);
      background: var(--color-bg);
      border-right: ${asRem(1)} solid var(--color-border);
      left: 0;

    }

    >.content-right {
      width: var(--layout-right-width);
      right: 0;
    }

    >.content-container {
      width: 100%;
      margin: auto;
      padding: var(--layout-top-height) var(--layout-right-width) var(--layout-bottom-height) var(--layout-left-width);
      >.content-body {
        margin: var(--layout-padding) auto;
      }
    }
  }

  &.no-left {
    --layout-left-width: 0;
  }

  &.no-right {
    --layout-right-width: 0;
  }

  &.no-bottom {
    --layout-bottom-height: 0;
  }

  &.no-top {
    --layout-top-height: 0;
    >.top {
      overflow: hidden;
    }
  }

  &.app-fullscreen {
    .hide-on-fullscreen {
      display: none;
    }
  }

  @media print {
    >.top {
      display: none;
    }
    >.content{
      >.content-left {
        display: none;
      }
      >.content-container {
        padding: 0;
        >.content-body {
          margin: 0;
        }
      }
    }
  }
`;

export const AppLayout = ({
  top, left, right, bottom, children, state,
}) => {
  const classes = ['app-root'];
  if (state.isFullScreen) classes.push('app-fullscreen');
  if (!top || !state.top || state.isFullScreen) classes.push('no-top');
  if (!left || !state.left || state.isFullScreen) classes.push('no-left');
  if (!right || !state.right || state.isFullScreen) classes.push('no-right');
  if (!bottom || !state.bottom || state.isFullScreen) classes.push('no-bottom');

  return (
    <AppLayoutWrapper className={`app-root ${classes.join(' ')}`}>
      <div className="top">{top}</div>
      <div className="content">
        {left && <div className="content-left">{left}</div>}
        <div className="content-container">
          <div className="content-body">
            {children}
          </div>
        </div>
        {right && <div className="content-right">{right}</div>}
      </div>
      {bottom && <div className="bottom">{bottom}</div>}
    </AppLayoutWrapper>
  );
};

AppLayout.propTypes = {
  children: PropTypes.element.isRequired,
  top: PropTypes.element.isRequired,
  left: PropTypes.element,
  right: PropTypes.element,
  bottom: PropTypes.element,
  state: PropTypes.object,
};
