import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const JobTitleList = lazy(() => import('./jobtitle/List'));
const JobTitleCreate = lazy(() => import('./jobtitle/Create'));
const JobTitleUpdate = lazy(() => import('./jobtitle/Update'));

const TeamList = lazy(() => import('./team/List'));
const TeamCreate = lazy(() => import('./team/Create'));
const TeamUpdate = lazy(() => import('./team/Update'));
const TeamMembers = lazy(() => import('./team/Members'));

export const RoleRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route path={Pages.hr.role.jobtitle.meta.path}>
          <Route
            index
            element={(<JobTitleList />)}
          />
          <Route
            path={Pages.hr.role.jobtitle.create.meta.path}
            element={(<JobTitleCreate />)}
          />
          <Route
            path={Pages.hr.role.jobtitle.update.meta.path}
            element={(<JobTitleUpdate />)}
          />
        </Route>
        <Route path={Pages.hr.role.team.meta.path}>
          <Route
            index
            element={(<TeamList />)}
          />
          <Route
            path={Pages.hr.role.team.create.meta.path}
            element={(<TeamCreate />)}
          />
          <Route
            path={Pages.hr.role.team.update.meta.path}
            element={(<TeamUpdate />)}
          />
          <Route
            path={Pages.hr.role.team.members.meta.path}
            element={(<TeamMembers />)}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};
