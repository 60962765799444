import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const LeaveBalanceList = lazy(() => import('./List'));
const LeaveCreate = lazy(() => import('./Create'));
const LeavesList = lazy(() => import('./Leaves'));
const LeaveHint = lazy(() => import('./Hint'));

export const LeaveRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<LeaveBalanceList />)}
        />
        <Route
          path={Pages.hr.staff.leave.create.meta.path}
          element={(<LeaveCreate />)}
        />
        <Route
          path={Pages.hr.staff.leave.hint.meta.path}
          element={(<LeaveHint />)}
        />
        <Route path={Pages.hr.staff.leave.leaves.meta.path}>
          <Route
            index
            element={(<LeavesList />)}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};
