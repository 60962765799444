import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  Formik, Form,
} from 'formik';
import * as Yup from 'yup';
import { FormItem } from 'core/FormItem';
import { Box } from 'core/Box';
import { LoginMutation, MyProfileQuery } from 'user/query';
import { ResponseStatus, StatusError, StatusLoading } from 'core/Status';
import { UserContext } from 'core/UserContext';
import { saveToken } from 'core/localStore';
import { Config } from 'config';
import { Pages } from 'core/pages';

const LoginPageWrapper = styled.div`
  .form-status {
    margin-bottom: var(--layout-padding-box);
  }
`;

export const LoginPage = ({ onLogin }) => {
  const userContext = useContext(UserContext);
  const formInitValues = {
    email: '',
    password: '',
  };

  const FormSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });

  const [token, setToken] = useState();

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(MyProfileQuery, {
    skip: !token,
  });

  const [
    doLogin,
    { loading: loginLoding, error: loginError, data: loginData },
  ] = useMutation(LoginMutation);

  const loading = queryLoading || loginLoding;
  const error = queryError || loginError;
  let status = null;
  if (queryData) {
    status = queryData.user_profile.status;
  } else if (loginData) {
    status = loginData.user_login.status;
  }

  useEffect(() => {
    if (queryData && queryData.user_profile && !userContext.user) {
      userContext.setUser(queryData.user_profile);
      if (onLogin) {
        onLogin(queryData.user_profile);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    doLogin({
      variables: {
        email: values.email,
        password: values.password,
      },
    }).then((resp) => {
      const respData = resp && resp.data && resp.data.user_login;
      if (respData && respData.token) {
        console.log('Performing Login');
        saveToken(respData.token);
        setToken(respData.token);
      } else {
        console.log('Unexpceted Response', resp);
      }
    }).finally(() => {
      setSubmitting(false);
    });
  };

  const parts = window.location.href.split('/');
  const basePath = `${parts[0]}//${parts[2]}`;
  const nextUrl = `${basePath}${Pages.pages.onLoginCallback.meta.url}?next=${encodeURIComponent(window.location.href)}`;
  const googleLoginUrl = `${Config.apiUrl}user/oauth/google-auth/?json=0&next_url=${encodeURIComponent(nextUrl)}`;

  return (
    <LoginPageWrapper>
      <Box center>
        <Box.Title>Login with Google</Box.Title>
        <Box.Content>
          <a
            href={googleLoginUrl}
            className="primary"
          >
            Login with Google
          </a>
        </Box.Content>
      </Box>
      <Formik
        initialValues={formInitValues}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Box center>
            <Box.Title>Login</Box.Title>
            <Box.Content>
              <Form>
                <FormItem label="Email" name="email" type="email" />
                <FormItem label="Password" name="password" type="password" />
                <div className="form-status">
                  {loading && (<StatusLoading message="Hold on..." />)}
                  {error && (<StatusError error={error} />)}
                  {status && (<ResponseStatus status={status} />)}
                </div>
                <button className="primary" type="submit" disabled={isSubmitting}>
                  Login
                </button>
              </Form>
            </Box.Content>
          </Box>
        )}
      </Formik>
    </LoginPageWrapper>
  );
};

LoginPage.propTypes = {
  onLogin: PropTypes.func,
};
