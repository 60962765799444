import React, { useContext } from 'react';
import styled from 'styled-components';
import { asRem } from 'lib/css';
import { UserContext } from './UserContext';

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: calc(100% - ${asRem(1)});

  h1 {
    padding: 0 var(--layout-padding-contained);
    font-size: ${asRem(16)};
    line-height: ${asRem(21)};
    align-self: center;
    display: block;
  }

  aside {
    display: block;
    button {
      display: block;
      margin-top: ${asRem(7)}
    }
  }
`;

export const TopBar = () => {
  const userContext = useContext(UserContext);
  return (
    <TopBarWrapper>
      <h1>Rumans - Roanuz</h1>
      {userContext.user && userContext.user.user && (
        <aside>
          <button className="plain" onClick={userContext.logout} type="button">
            Logout
            {' '}
            {userContext.user.user.profile.first_name}
          </button>
        </aside>
      )}
    </TopBarWrapper>
  );
};

TopBar.propTypes = {
};
