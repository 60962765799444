import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const SalaryList = lazy(() => import('./staff/List'));
const AnnualCTCList = lazy(() => import('./ctc/List'));
const AnnualCTCCreate = lazy(() => import('./ctc/Create'));
const AnnualCTCUpdate = lazy(() => import('./ctc/Update'));

export const SalaryRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<SalaryList />)}
        />
        <Route path={Pages.hr.staff.salary.ctc.meta.path}>
          <Route
            index
            element={(<AnnualCTCList />)}
          />
          <Route
            path={Pages.hr.staff.salary.ctc.create.meta.path}
            element={(<AnnualCTCCreate />)}
          />
          <Route
            path={Pages.hr.staff.salary.ctc.update.meta.path}
            element={(<AnnualCTCUpdate />)}
          />
        </Route>

      </Routes>
    </Suspense>
  );
};
