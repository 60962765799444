import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'core/pages';

const OfficeList = lazy(() => import('./List'));
const OfficeCreate = lazy(() => import('./Create'));
const OfficeUpdate = lazy(() => import('./Update'));
const Workdays = lazy(() => import('./salary/Workdays'));
const Salary = lazy(() => import('./salary/Salary'));
const SalaryView = lazy(() => import('./salary/SalaryView'));
const OfficeLeaves = lazy(() => import('./Leaves'));
const OfficeLeaveBalances = lazy(() => import('./LeaveBalances'));

export const OfficeRoutes = () => {
  return (
    <Suspense fallback={<>...</>}>
      <Routes>
        <Route
          index
          element={(<OfficeList />)}
        />
        <Route
          path={Pages.hr.staff.office.create.meta.path}
          element={(<OfficeCreate />)}
        />
        <Route
          path={Pages.hr.staff.office.update.meta.path}
          element={(<OfficeUpdate />)}
        />
        <Route
          path={Pages.hr.staff.office.month.meta.starPath}
        >
          <Route
            path={Pages.hr.staff.office.month.workdays.meta.path}
            element={(<Workdays />)}
          />
          <Route
            path={Pages.hr.staff.office.month.salary.meta.path}
            element={(<Salary />)}
          />
          <Route
            path={Pages.hr.staff.office.month.view.meta.path}
            element={(<SalaryView />)}
          />
          <Route
            path={Pages.hr.staff.office.month.leaves.meta.path}
            element={(<OfficeLeaves />)}
          />
          <Route
            path={Pages.hr.staff.office.month.leavebalances.meta.path}
            element={(<OfficeLeaveBalances />)}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};
