import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { MyProfileQuery } from 'user/query';
import { AppScreenError, AppScreenLoading } from './Screen';
import { getToken, removeToken } from './localStore';
import { AppContext } from './AppContext';

const defaultContext = {
  user: null,
  loggedIn: false,
};

export const UserContext = React.createContext({ ...defaultContext });
export const UserContextConsumer = UserContext.Consumer;

export const UserContextProvider = ({ value, children }) => {
  const appContext = useContext(AppContext);
  const [userContext, setUserContext] = useState({
    setLoggedIn: (loggedIn) => {
      setUserContext((state) => ({
        ...state,
        loggedIn,
      }));
    },
    setUser: (user) => {
      let loggedIn = false;
      if (user && user.status.okay) {
        loggedIn = true;
      }
      setUserContext((state) => ({
        ...state,
        user,
        loggedIn,
      }));
      if (loggedIn) {
        appContext.setViewStateValue('left', true);
      } else {
        appContext.setViewStateValue('left', false);
      }
    },
    logout: () => {
      removeToken();
      userContext.setUser(null);
    },
    ...value,
  });

  const token = getToken();
  const { loading, error, data } = useQuery(MyProfileQuery, {
    skip: !token,
  });

  useEffect(() => {
    if (userContext.user && !userContext.loggedIn) {
      userContext.setLoggedIn(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext.user, userContext.loggedIn]);

  useEffect(() => {
    if (data && data.user_profile && !userContext.user) {
      userContext.setUser(data.user_profile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error && error.graphQLErrors) {
      error.graphQLErrors.forEach((errorItem) => {
        if (errorItem.extensions && errorItem.extensions.rzerror) {
          const errorData = errorItem.extensions.rzerror;
          if (errorData.class === 'InvalidTokenError') {
            userContext.logout();
          }
        }
      });
      console.log('Error', error.graphQLErrors);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (loading) return (<AppScreenLoading />);
  if (error) return (<AppScreenError error={error} />);

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
};
