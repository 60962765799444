import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Config } from 'config';
import { Box } from 'core/Box';

const StatusLoadingWrapper = styled.div`
  color: var(--color-primary);
  font-style: italic;
`;

export const StatusLoading = ({ message }) => {
  const msg = message || 'Loading...';
  return (
    <StatusLoadingWrapper>
      <Box center>
        {msg}
      </Box>
    </StatusLoadingWrapper>
  );
};

StatusLoading.propTypes = {
  message: PropTypes.string,
};

const StatusErrorWrapper = styled.div`
  color: var(--color-error);
  font-style: italic;
`;

export const StatusError = ({ error, log = true }) => {
  if (!Config.IsProd && log) {
    console.log('Error');
    console.error(error);
  }
  return (
    <StatusErrorWrapper>
      <Box center>
        {error ? (error.message || error.msg) : 'Oops something is wrong!'}
      </Box>
    </StatusErrorWrapper>
  );
};

StatusError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    msg: PropTypes.string,
  }),
  log: PropTypes.bool,
};

const StatusEmptyWrapper = styled.div`
  color: var(--color-primary);
  font-style: italic;
  text-align: center;
`;

export const StatusEmpty = ({ message }) => {
  const msg = message || 'You got nothing here!';
  return (
    <StatusEmptyWrapper>
      <Box center>
        {msg}
      </Box>
    </StatusEmptyWrapper>
  );
};

StatusEmpty.propTypes = {
  message: PropTypes.string,
};

const ResponseStatusWrapper = styled.div`
  color: var(--color-primary);
  margin-bottom: var(--layout-padding-contained);
`;

export const ResponseStatus = ({ status }) => {
  return (
    <ResponseStatusWrapper>
      {status && status.okay
        ? (
          <div>✅ All done!</div>
        )
        : (
          <div>
            {!status && (
              <StatusError
                error={{ message: 'Status missing' }}
                log={false}
              />
            )}
          </div>
        )}
      <>
        {status && status.errors && status.errors.map((item, i) => (
          <StatusError
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            error={{ message: item.msg }}
            log={false}
          />
        )) }
      </>
    </ResponseStatusWrapper>
  );
};

ResponseStatus.propTypes = {
  status: PropTypes.shape({
    okay: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.shape({
      msg: PropTypes.string.isRequired,
      code: PropTypes.string,
    })),
  }),
};
