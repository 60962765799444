import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Config } from 'config';

const AppScreenLoadingWrapper = styled.div`
`;

export const AppScreenLoading = ({ message }) => {
  const msg = message || 'App is loading...';
  return (
    <AppScreenLoadingWrapper>
      {msg}
    </AppScreenLoadingWrapper>
  );
};

AppScreenLoading.propTypes = {
  message: PropTypes.string,
};

const AppScreenErrorWrapper = styled.div`
`;

export const AppScreenError = ({ error }) => {
  if (!Config.IsProd) {
    console.log('App Error');
    console.error(error);
  }
  return (
    <AppScreenErrorWrapper>
      {error ? error.message : 'Oops something is wrong!'}
    </AppScreenErrorWrapper>
  );
};

AppScreenError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};
